<template>
  <div class="useral-edit-profile" :class="[{'main-box top-zero-radius p-2': $route.name === 'editProfile', 'no-box-shadow p-0': $route.name !== 'editProfile'}]">
    <div class="edit-profile-fields">
      <custom-profile-image-input v-model="user.image"/>
      <div class="input-field-item" :class="user.gender.id === -1 ? 'invalid' : ''">
        <label class="input-field-label">{{ $t('profile.edit.labels.gender') }}</label>
        <div v-for="(item, key) in gender" :key="key" class="radio-item">
          <label :for="`genderRadio${key}`">{{ item.label }}</label>
          <input :id="`genderRadio${key}`" type="radio" :key="key" name="refereee" :value="item.id"
                 v-model="user.gender.id"/>
        </div>
      </div>


      <custom-validate-input :label="$t('profile.edit.labels.name')"
                             :classes="{'w-full': true}"
                             align="right"
                             rtl
                             :invalid-text="$t('profile.edit.invalidTexts.name')"
                             :regex="nameRegex"
                             v-model="user.name"/>

      <custom-validate-input :label="$t('profile.edit.labels.family')"
                             :classes="{'w-full': true}"
                             align="right"
                             rtl
                             :invalid-text="$t('profile.edit.invalidTexts.family')"
                             :regex="nameRegex"
                             v-model="user.lastName"/>
      <u-phone-input v-model="user.phoneNumber"
                     :label="$t('profile.edit.labels.phoneNumber')"
                     auto-focus
                     disabled
                     :default-country-code="clubInfo.club_default_country_code"
      />

    </div>

    <vs-button v-show="false"
               id="EditProfileBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import {mapGetters} from "vuex";
  import CustomValidateInput from '../../../../components/customInput/customValidateInput'
  import CustomProfileImageInput from "../../../../components/customInput/customProfileImageInput";
  import UPhoneInput from "../../../../components/customInput/UPhoneInput.vue";
  import {editProfile, getProfile} from '../../../../http/requests/users/users'
  import {getRoles} from '../../../../http/requests/settings/roles'
  import {checkUserPermissions} from '../../../../assets/js/functions'
  import {getCharacters} from "../../../../http/requests/characters";

  export default {
    name: 'editProfile',
    components: {
      UPhoneInput,
      CustomProfileImageInput,
      CustomValidateInput
    },
    metaInfo () {
      return {
        title: this.$t('profile.edit.title')
      }
    },
    props: {
      userId: {
        type: [Number, String],
        default: 0
      }
    },
    data() {
      return {
        nameRegex: this.$validator('regex.user.name'),
        characters: [],
        gender: [
          {
            id: 1,
            label: this.$t('genderTypes.man')
          },
          {
            id: 2,
            label: this.$t('genderTypes.woman')
          }
        ],
        user: {
          name: {
            value: '',
            isValid: true
          },
          lastName: {
            value: '',
            isValid: true
          },
          fatherName: {
            value: '',
            isValid: true
          },
          nationalCode: {
            value: '',
            isValid: false
          },
          image: {
            value: '',
            isValid: true
          },
          gender: {
            id: -1,
            label: this.$t('genderTypes.man')
          },
          phoneNumber: {
            value: '',
            isValid: true
          },
          email: {
            value: '',
            isValid: true
          },
          character: {
            id: 1
          },
          role: {
            label: '-',
            value: null
          },
          balance: 0,
          maxDebt: 0,
        },
        roles: [],
        actions: [
          {
            toolbar: [
              {
                id: 'EditProfileBTN',
                icon: 'SAVE',
                iconPack: 'useral',
                color: 'success'
              }
            ],
            leftToolbar: [
              {
                id: 'routeBackBTN',
                route: {name: 'Profile'},
                icon: 'CHEVRON_LEFT',
                iconPack: 'useral'
              }
            ]
          }
        ]
      }
    },
    computed: {
      ...mapGetters({
        clubInfo: 'setting/getClubInfo'
      })
    },
    created() {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'mx-auto')
      }, 50)

      this.getUser()
      if (checkUserPermissions('role.show')) {
        this.getAccessGroups()
      }
      if (checkUserPermissions('character.show')) {
        this.getCharacters()
      }
    },
    methods: {
      getUser() {
        getProfile().then(response => {
          const user = response.data.data

          this.user = {
            id: user.id,
            image: {
              value: user.avatar || '',
              isValid: true
            },
            name: {
              value: user.name,
              isValid: ''
            },
            companyName: {
              value: '',
              isValid: ''
            },
            lastName: {
              value: user.family,
              isValid: ''
            },
            gender: {
              id: user.gender
            },
            email: {
              value: user.email ? user.email : '',
              isValid: ''
            },
            phoneNumber: {
              value: user.phone_number,
              isValid: ''
            },
            financial: {
              maxDebt: user.maxDebt ? user.maxDebt : 0,
              balance: user.balance ? user.balance : 0
            }
          }
          this.$store.dispatch('setPageTitle', this.$t('profile.edit.title', {name: `${this.user.name.value} ${this.user.lastName.value}`}))
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            }
          })
      },
      getCharacters() {
        getCharacters().then(response => {
          const characters = response.data.data
          characters.forEach(character => {
            this.characters.push({
              id: character.id,
              label: character.name
            })
          })
        })
      },
      getAccessGroups() {
        getRoles().then(response => {
          this.roles = this.getRoles(response.data.data)
        })
      },
      getRoles(role) {
        let roles = [
          {
            label: role.name,
            value: role.id
          }
        ]

        if (role.children && role.children.length > 0) {
          role.children.forEach((child) => {
            roles = [...roles, ...this.getRoles(child)]
          })
        }
        return roles
      },
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      sendData() {
        if (this.user.gender.id === -1) {

          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('profile.notifications.parseError.gender'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }
        if (!this.user.name.isValid) {

          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('profile.notifications.parseError.name'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }
        if (!this.user.lastName.isValid) {

          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('profile.notifications.parseError.family'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }

        const user = {
          name: this.user.name.value,
          family: this.user.lastName.value,
          avatar: this.user.image ? this.user.image.value : '',
          gender: this.user.gender.id,
          phone_number: this.user.phoneNumber.value,
          company: '',
        }

        editProfile(user).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('profile.notifications.edit.success'),
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400,
            color: 'success'
          })
          if (this.$route.name === 'editProfile') {
            this.$router.back()
          } else {
            this.$emit('edited')
          }
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {

            const error_mapper = {
              'name': this.$t('profile.notifications.parseError.name'),
              'family': this.$t('profile.notifications.parseError.family'),
              'avatar': this.$t('profile.notifications.parseError.avatar'),
              'gender': this.$t('profile.notifications.parseError.gender'),
              'phone_number': this.$t('profile.notifications.parseError.phoneNumber'),
            }

            switch (error.response.status) {
              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    error_mapper[err[err.length - 1]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('profile.notifications.edit.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .useral-edit-profile {
    width: 100%;
    height: calc(100% - 10px);
    display: flex;
    background-color: #fff;

    @media (max-width: 767px) {
      display: block;
    }

    .edit-profile-fields {
      max-width: 400px;
      width: 100%;

      .custom-profile-image-input {
        .profile-image-label {
          height: 110px;
          width: 110px;
        }
      }

      .input-field-item {
        position: relative;
        border: 1px solid #cdcdcd;
        border-radius: 0.4rem;
        padding: 5px 10px;
        margin: 15px 0;
        display: flex;
        justify-content: space-around;
        min-height: 35px;
        flex-wrap: wrap;

        &.character-input {
          justify-content: space-between;
        }

        &.invalid {
          border-color: #b00000;

          .input-field-label {
            color: #b00000;
          }
        }

        .input-field-label {
          position: absolute;
          font-size: 12px;
          top: -10px;
          left: 8px;
          background-color: #ffffff;
          padding: 0 5px;
        }

        .radio-item {
          display: flex;
          align-items: center;
          width: max-content;
          direction: rtl;

          label {
            direction: ltr;
            margin-left: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &.character-input {
          justify-content: space-between;

          .radio-item {
            justify-content: flex-end;
            width: 33%;
          }
        }
      }

      div.edit-user-field {
        position: relative;

        span.label {
          font-size: 12px;
          position: absolute;
          top: -10px;
          left: 7px;
          z-index: 10000;
          background: #ffffff;
          padding: 0 5px;
        }

        .useral-custom-element-select {
          margin: 15px 0;

          .selected {
            height: 35px;
            line-height: 35px;
            font-size: 13px;
          }

          .items {
            line-height: 35px;

            div {
              height: 35px;
              font-size: 13px;
            }
          }
        }
      }

      .error-alert {
        display: inline-block;
        border: 1px solid #ffba00;
        border-radius: .5rem;
        color: #ffba00;
        padding: 0 10px;
        line-height: 50px;
        height: 50px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 15px;

        i {
          font-size: 15px;
        }
      }

      &::-webkit-scrollbar {
        display: block;
        border-radius: .2rem;
        width: 10px;
        background: #f9f9fd;
        right: 10px;
      }

      &::-webkit-scrollbar-thumb {
        display: block;
        border-radius: .2rem;
        background: #cecece;
      }
    }
  }
</style>
