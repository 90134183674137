<template>
  <vx-card class="user-profile-page main-box top-zero-radius"
           v-if="Object.keys(user).length > 0">

    <profile-main-box :profile="getUserProfile"
                      :default-action="profileStatus"
                      :actions-list="actionsList"
                      @profileInvoices="handleChangeProfileStatus('profileInvoices')"
                      @profileActiveSessions="handleChangeProfileStatus('profileActiveSessions')"
                      @profileReceivedEvents="handleChangeProfileStatus('profileReceivedEvents')"
                      @profileSentEvents="handleChangeProfileStatus('profileSentEvents')">

      <profile-invoices-list v-if="profileStatus === 'profileInvoices'"/>

      <profile-active-sessions v-else-if="profileStatus === 'profileActiveSessions'" :max-active-sessions="maxActiveSessions" @changeActiveSession="handleChangeActiveSession"/>

      <profile-events v-else-if="profileStatus === 'profileReceivedEvents'" type="received" />

      <profile-events v-else-if="profileStatus === 'profileSentEvents'" type="sent" />

      <edit-profile v-if="profileStatus === 'editProfile'" @edited="handleChangeProfileStatus('profileInvoices'), getProfile()"/>
    </profile-main-box>

    <vs-button id="editProfileBTN" class="useral-action-button" @click="handleChangeProfileStatus('editProfile')"/>
  </vx-card>
</template>

<script>
  import axios from 'axios'
  import {mapGetters} from "vuex";
  import ProfileMainBox from '@/components/profileMainBox/profileMainBox2.vue'
  import EditProfile from "../edit/editProfile";
  import CustomIcon from "../../../../components/customIcon/customIcon";
  import ProfileInvoicesList from "../invoices/profileInvoices";
  import CoachClassesList from "../classes/coachClassesList";
  import ProfileEvents from "@/views/admin/profile/events/profileEvents.vue";
  import {getProfile} from '@/http/requests/users/users'
  import {getAvatarUrl} from '@/assets/js/functions'
  import ProfileActiveSessions from "@/views/admin/profile/activeSessions/profileActiveSessions.vue";

  export default {
    name: 'profile',
    components: {
      ProfileActiveSessions,
      ProfileEvents, CoachClassesList, ProfileInvoicesList, CustomIcon, EditProfile, ProfileMainBox},
    data() {
      return {
        profileStatus: 'profileInvoices',
        actions: {
          toolbar: [
            {
              id: 'editProfileBTN',
              icon: 'EDIT',
              iconPack: 'useral',
              color: 'warning'
            }
          ]
        },
        maxActiveSessions: 0,
        user: {},
        actionsList: [
          {
            event: 'profileInvoices',
            name: 'profileInvoices',
            header: '',
            i18n: 'profile.actions.invoices',
            icon: 'INVOICE',
            colorCode: '#000',
            iconPack: 'useral'
          },
          {
            event: 'profileActiveSessions',
            name: 'profileActiveSessions',
            header: '',
            i18n: 'profile.actions.activeSessions',
            icon: 'ACTIVE_SESSIONS',
            iconPack: 'useral'
          },
          {
            event: 'profileReceivedEvents',
            name: 'profileReceivedEvents',
            header: '',
            i18n: 'profile.actions.receivedEvents',
            icon: 'icon-message-circle',
            iconPack: 'feather'
          },
          {
            event: 'profileSentEvents',
            name: 'profileSentEvents',
            header: '',
            i18n: 'profile.actions.sentEvents',
            icon: 'icon-send',
            iconPack: 'feather',
            permission: 'event.create'
          }
        ]
      }
    },
    created() {
      if (this.isMobile) {
        this.actions.toolbar[0].id = {name: 'editProfile'}
        this.actions.toolbar[0].type = 'link'
      }
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'mx-auto')
      }, 50)

      this.getProfile()
    },
    computed: {
      ...mapGetters({
        isMobile: 'isMobile'
      }),
      getUserProfile() {
        let user = {
          avatar: this.user.avatar ? getAvatarUrl(this.user.avatar) : this.user.general.gender === 1 ? require('@/assets/images/man-avatar.png') : require('@/assets/images/woman-avatar.png'),
          name: `${this.user.general.name.value} ${this.user.general.lastName.value}`,
          general: this.user.general,
          importantData: [
            // {
            //   content: `${this.user.general.name.value} ${this.user.general.lastName.value}`,
            //   preContent: this.user.general.gender === 1 ? this.$t('genderTypes.dynamicMan') : this.user.general.gender === 2 ? this.$t('genderTypes.woman') : ''
            // },
            {
              value: this.user.general.phoneNumber.value,
              key: this.$t('profile.asiderAvatar.phoneNumber'),
              valueClass: this.$vs.rtl ? 'rtl' : 'ltr'
            }
          ],
          details: []
        }

        if (this.user.character) {
          user.importantData.push({
            value: this.user.character ? this.user.character.name : '-',
            key: this.$t('profile.asiderAvatar.character')
          })
        }

        if (this.user.access) {
          user.importantData.push({
            value: this.user.access ? this.user.access.name : '',
            key: this.$t('profile.asiderAvatar.access')
          })
        }

        return user
      }
    },
    methods: {
      getProfile() {
        getProfile().then(response => {
          const user = response.data.data
          this.user = {
            id: user.id,
            avatar: user.avatar || '',
            general: {
              name: {
                value: user.name,
                isInvalid: ''
              },
              companyName: {
                value: '',
                isInvalid: ''
              },
              lastName: {
                value: user.family,
                isInvalid: ''
              },
              gender: user.gender,
              email: {
                value: user.email ? user.email : '',
                isInvalid: ''
              },
              phoneNumber: {
                value: user.phone_number,
                isInvalid: ''
              }
            },
            coachId: user.coach_id,
            character: user.character,
            activeSessions: user.active_sessions,
            access: user.role,
            group: user.group,
            financial: {
              maxDebt: user.maxDebt ? user.maxDebt : 0,
              balance: user.balance ? user.balance : 0
            }
          }

          if (user.is_coach) {
            let actionsList = JSON.parse(JSON.stringify(this.actionsList))
            const coachClasses = {
              event: 'coachClasses',
              i18n: 'users.user.profile.actions.coachClasses',
              icon: 'CALENDER',
              colorCode: '#000',
              iconPack: 'useral',
            }

            actionsList.splice(0, 0, coachClasses)
            this.actionsList = []
            setTimeout(() => {
              this.actionsList = actionsList
            }, 50)
          }

          this.maxActiveSessions = user.role.allowed_active_sessions

          if (user.active_sessions > user.role.allowed_active_sessions) {
            this.profileStatus = 'profileActiveSessions'
          }

          if (!this._isBeingDestroyed) {
            this.$store.dispatch('setPageTitle', this.$t('profile.dynamicTitle', {name: `${this.user.general.name.value} ${this.user.general.lastName.value}`}))
          }
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            }
          })
      },
      handleChangeActiveSession (activeSession) {
        this.user.activeSessions = activeSession
      },
      handleChangeProfileStatus (status) {
        this.profileStatus = ''
        this.$nextTick(() => {
          this.profileStatus = status
        })
      },
      handleClick(id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style lang="scss">
  .user-profile-page {
    .vx-card__body {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }
</style>
